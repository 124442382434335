import React, { FunctionComponent } from 'react';
import { generateCloudinaryUrl } from '../../../helpers/cloudinary-helper/cloudinary-helper';
import { CloudinaryImage } from '../../images/cloudinary-image/cloudinary-image.component';
import { DoorMarker } from '../promo-helpers/door-marker.component';
import { defaultCouponExpirationString } from '../promo-helpers/promo-banner.helpers';
import { PromoBannerVariationOptions } from '../../../helpers/recommendations/recommendations.helper';

export const SecondaryPromoA: FunctionComponent<PromoBannerVariationOptions> = ({
	headline,
	subHeading,
	couponCode,
	expirationDate,
	action,
	image,
	imageOverlay
}) => {
	const backgroundUrl = generateCloudinaryUrl(image.id, {
		width: 600,
		height: undefined,
		gravity: 'custom',
		zoom: '0.9',
		crop: 'thumb'
	});
	return (
		<div className={`ba b--theme-tertiary flex-ns flex-column`}>
			{/* TEXT */}
			<div className="bl b--theme-grey bw2 bg-theme-white pv2 ph3">
				<div className="b f4 theme-primary lh-title">{headline}</div>
				<div className="f6 mv2 theme-grey-darker lh-title">{subHeading}</div>
				{(couponCode || expirationDate) && (
					<div className="f7 mt2 theme-grey-darker">
						<DoorMarker />
						<span className="nowrap">{defaultCouponExpirationString(couponCode, expirationDate)}</span>
					</div>
				)}
			</div>
			{/* IMAGE */}
			<div
				className="cover min-h4 relative"
				style={{
					background: `url(${backgroundUrl}) no-repeat center center`
				}}>
				{imageOverlay && (
					<CloudinaryImage
						publicID={imageOverlay.id}
						description="promo"
						className="absolute left-0 max-h-100 mw-50 pa3 top-0"
						options={{ width: 200, height: undefined, crop: 'lfill', gravity: 'custom', preserveTransparency: true }}
						useDimensions={false}
					/>
				)}
				<div className="absolute bottom-0 pa3 right-0 theme-white">{action?.name || ''}</div>
			</div>
		</div>
	);
};
